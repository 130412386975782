import React from 'react';
import styled from 'styled-components';
import { FaShoppingCart, FaBolt, FaUsers } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ActionButton from '../common/ActionButton';

const ItemDetailsContainer = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  padding: 16px;
  max-width: 600px;
  margin: 0 auto;
`;

const ItemDetailsImage = styled(LazyLoadImage)`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #f5f5f5;
`;

const ItemDetailsTitle = styled.h2`
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 8px;
  font-weight: 500;
`;

const ItemDetailsPrice = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const PriceLabel = styled.span`
  font-size: 0.875rem;
  color: #333;
  font-weight: 600;
`;

const Price = styled.span`
  font-size: 1rem;
  color: #333;
  margin-left: 4px;
`;

const GoalContainer = styled.div`
  margin: 12px 0;
`;

const GoalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const GoalText = styled.span`
  font-size: 0.75rem;
  color: #666;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  width: ${props => props.$progress}%;
  height: 100%;
  background-color: #2196f3;
  transition: width 0.3s ease;
`;

const ApplicantsInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 8px;
`;

const ApplicantsCount = styled.span`
  font-size: 0.875rem;
  color: #666;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 16px;
`;

const ItemDetails = ({ item, onBackClick, onAddToCart, onBuyNow }) => {
  // Calculate progress percentage
  const progressPercentage = Math.min(
    (parseFloat(item.current_amount) / parseFloat(item.target_amount)) * 100,
    100
  );

  return (
    <>
      <ActionButton onClick={onBackClick} variant="back">Back to Items</ActionButton>
      <ItemDetailsContainer>
        <ItemDetailsImage
          src={item.image || '/placeholder-image.jpg'}
          alt={item.title}
          effect="blur"
        />
        <ItemDetailsTitle>{item.title}</ItemDetailsTitle>
        <ItemDetailsPrice>
          <PriceLabel>Rs.</PriceLabel>
          <Price>{item.price}</Price>
        </ItemDetailsPrice>

        <GoalContainer>
          <GoalInfo>
            <GoalText>Rs. {parseFloat(item.current_amount).toLocaleString()}</GoalText>
            <GoalText>Goal: Rs. {parseFloat(item.target_amount).toLocaleString()}</GoalText>
          </GoalInfo>
          <ProgressBarContainer>
            <ProgressBar $progress={progressPercentage} />
          </ProgressBarContainer>
          <ApplicantsInfo>
            <ApplicantsCount>
              <FaUsers size={14} color="#666" />
              {item.applicants_count} Applied
            </ApplicantsCount>
          </ApplicantsInfo>
        </GoalContainer>

        <ButtonContainer>
          <ActionButton onClick={(e) => onAddToCart(e, item)} variant="cart">
            <FaShoppingCart /> Cart
          </ActionButton>
          <ActionButton onClick={(e) => onBuyNow(e, item)} variant="buy">
            Apply Now
          </ActionButton>
        </ButtonContainer>
      </ItemDetailsContainer>
    </>
  );
};

export default ItemDetails;