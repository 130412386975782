import React, { useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import { useCart } from '../../CartContext';
import SearchBar from '../Search/SearchBar'; 
import FeaturedSlider from '../FeaturedItems/FeaturedSlider';
import ItemDetails from '../ItemDetails/ItemDetails';
import api from '../../utils/api';
import { FaShoppingCart, FaBolt, FaUsers } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useParams, useNavigate } from 'react-router-dom';
import AllItemsSection from './AllItemsSection';


// =========== Styled Components ===========
const HomeContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px 20px;
  margin-top: -40px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  color: #333;
  margin: 30px 0 20px;
  text-align: center;

  &:first-of-type {
    margin-top: 150px;
  }

  &:last-of-type {
    margin-top: 30px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2em;
  color: #666;
`;

const ErrorContainer = styled.div`
  padding: 20px;
  text-align: center;
  color: #d32f2f;
  background-color: #ffebee;
  border-radius: 4px;
  margin: 20px 0;
`;

const BackButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9em;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #e0e0e0;
  }
`;

const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
`;

const CategoryCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 15px 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.12);
    background-color: #f8f9fa;
  }
`;

const CategoryTitle = styled.h3`
  font-size: 0.9em;
  color: #333;
  margin: 0;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.2px;
`;

const ItemsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;

const ItemCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ItemImage = styled(LazyLoadImage)`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const ItemTitle = styled.h3`
  font-size: 1.1em;
  margin: 8px 0;
  color: #333;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const ItemPrice = styled.p`
  font-weight: bold;
  color: #e43d12;
  font-size: 1.1em;
  margin: 8px 0;

  @media (max-width: 768px) {
    font-size: 0.95em;
  }
`;

const ProgressContainer = styled.div`
  margin: 4px 0 12px;
`;

const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const ApplicationCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9em;
  color: #666;

  svg {
    color: #2196F3;
  }
`;

const ApplicationText = styled.span`
  color: #2196F3;
  font-weight: 500;
`;

const GoalAmount = styled.span`
  font-size: 0.9em;
  color: #666;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  width: ${props => props.$progress}%;
  height: 100%;
  background-color: #2196F3;
  border-radius: 2px;
  transition: width 0.3s ease;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: auto;
  padding-top: 12px;

  @media (max-width: 768px) {
    gap: 6px;
    padding-top: 8px;
  }
`;

const ActionButton = styled.button`
  flex: 1;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    padding: 6px 8px;
    font-size: 0.8em;
    gap: 3px;
  }
`;

const AddToCartButton = styled(ActionButton)`
  background-color: #4CAF50;
  color: white;

  &:hover {
    background-color: #45a049;
  }
`;

const BuyButton = styled(ActionButton)`
  background-color: #2196F3;
  color: white;

  &:hover {
    background-color: #1e88e5;
  }
`;

const LoadMoreButton = styled.button`
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  margin: 32px auto;
  display: block;
  transition: all 0.3s ease;

  &:hover {
    background-color: #1976D2;
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

// =========== API Service ===========
const apiService = {
  fetchCategories: async () => {
    try {
      const response = await api.get('/api/categories/');
      return Array.isArray(response.data) ? response.data : response.data.results || [];
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  },

  fetchFeaturedItems: async () => {
    try {
      const response = await api.get('/api/featured-items/');
      return Array.isArray(response.data) ? response.data : response.data.results || [];
    } catch (error) {
      console.error('Error fetching featured items:', error);
      throw new Error('Failed to fetch featured items');
    }
  },

  fetchAllItems: async (page = 1) => {
    try {
      const response = await api.get('/api/items/', {
        params: {
          page,
          page_size: 10
        }
      });
      return {
        items: response.data.results || [],
        hasMore: response.data.next !== null
      };
    } catch (error) {
      console.error('Error fetching all items:', error);
      throw new Error('Failed to fetch all items');
    }
  },

  fetchSearchSuggestions: async (term) => {
    try {
      const response = await api.get(`/api/search-suggestions/?term=${term}`);
      return response.data.suggestions || [];
    } catch (error) {
      console.error('Error fetching search suggestions:', error);
      return [];
    }
  },

  fetchCategoryItems: async (categoryId, page = 1) => {
    try {
      const response = await api.get('/api/items/', {
        params: {
          category: categoryId,
          page,
          page_size: 10
        }
      });

      return {
        items: response.data.results || [],
        hasMore: response.data.next !== null,
        total: response.data.count || 0
      };
    } catch (error) {
      console.error('Error fetching category items:', error);
      throw new Error('Failed to fetch category items');
    }
  }

};

// =========== Utility Functions ===========
const formatCurrency = (amount) => {
  if (amount === undefined || amount === null) return 'Rs. 0';
  try {
    return `Rs. ${Number(amount).toLocaleString()}`;
  } catch (error) {
    console.error('Error formatting currency:', error);
    return 'Rs. 0';
  }
};

// =========== Main Component ===========
const HomePage = () => {
  const { categorySlug } = useParams();
  const navigate = useNavigate();
  const { addToCart, buyNow } = useCart();

  const [state, setState] = useState({
    featuredItems: [],
    categories: [],
    allItems: [],
    loading: true,
    loadingMore: false,
    allItemsLoading: false,
    error: null,
    currentPairIndex: 0,
    searchTerm: '',
    suggestions: [],
    selectedItem: null,
    selectedCategory: null,
    currentPage: 1,
    selectedFilter: 'all',
    hasMore: true
  });

  // Item Card Renderer
  const renderItemCard = (item) => {
    const progress = item.target_amount > 0 
      ? (item.current_amount / item.target_amount) * 100 
      : 0;
    const numericApplicants = Number(item.applicants_count) || 0;

    return (
      <ItemCard 
        key={item.id}
        onClick={() => handlers.selectItem(item)}
      >
        <ItemImage
          src={item.image || '/placeholder-image.jpg'}
          alt={item.title}
          effect="blur"
        />
        <ItemTitle>{item.title}</ItemTitle>
        <ItemPrice>{formatCurrency(item.price)}</ItemPrice>
        
        <ProgressContainer>
          <ProgressHeader>
            <ApplicationCount>
              <FaUsers size={14} />
              <ApplicationText>{numericApplicants} Applied</ApplicationText>
            </ApplicationCount>
            <GoalAmount>Goal: {formatCurrency(item.target_amount)}</GoalAmount>
          </ProgressHeader>
          <ProgressBarContainer>
            <ProgressBar $progress={progress} />
          </ProgressBarContainer>
        </ProgressContainer>

        <ButtonContainer>
          <AddToCartButton onClick={(e) => handlers.addToCart(e, item)}>
            <FaShoppingCart /> Add
          </AddToCartButton>
          <BuyButton onClick={(e) => handlers.buyNow(e, item)}>
            <FaBolt /> Apply
          </BuyButton>
        </ButtonContainer>
      </ItemCard>
    );
  };

  // Define handlers with useMemo to prevent infinite loops
  const handlers = useMemo(() => ({
    loadMore: async () => {
      if (state.loadingMore) return;
    
      setState(prev => ({ ...prev, loadingMore: true }));
      try {
        const nextPage = state.currentPage + 1;
        let response;
        
        if (state.selectedFilter === 'all') {
          response = await apiService.fetchAllItems(nextPage);
        } else {
          response = await apiService.fetchCategoryItems(state.selectedFilter, nextPage);
        }
    
        setState(prev => ({
          ...prev,
          allItems: [...prev.allItems, ...response.items],
          currentPage: nextPage,
          hasMore: response.hasMore,
          loadingMore: false
        }));
      } catch (error) {
        console.error('Error loading more items:', error);
        setState(prev => ({
          ...prev,
          error: 'Failed to load more items',
          loadingMore: false
        }));
      }
    },

    filterItems: async (categoryId) => {
      // Convert categoryId to number if it's not 'all'
      const numericCategoryId = categoryId === 'all' ? 'all' : parseInt(categoryId);
      
      setState(prev => ({ 
        ...prev, 
        selectedFilter: numericCategoryId,
        allItemsLoading: true,
        error: null,
        currentPage: 1  // Reset page when changing filters
      }));
  
      try {
        let response;
        if (numericCategoryId === 'all') {
          response = await apiService.fetchAllItems(1);
        } else {
          response = await apiService.fetchCategoryItems(numericCategoryId, 1);
        }
  
        setState(prev => ({
          ...prev,
          allItems: response.items,
          hasMore: response.hasMore,
          allItemsLoading: false
        }));
      } catch (error) {
        console.error('Error filtering items:', error);
        setState(prev => ({
          ...prev,
          error: 'Failed to filter items',
          allItemsLoading: false
        }));
      }
    },

    search: async (term) => {
      setState(prev => ({ ...prev, searchTerm: term }));
      if (term.length > 1) {
        try {
          const suggestions = await apiService.fetchSearchSuggestions(term);
          setState(prev => ({ ...prev, suggestions }));
        } catch (error) {
          console.error('Error fetching suggestions:', error);
          setState(prev => ({ ...prev, suggestions: [] }));
        }
      } else {
        setState(prev => ({ ...prev, suggestions: [] }));
      }
    },

    selectCategory: async (category) => {
      setState(prev => ({ 
        ...prev, 
        selectedCategory: category,
        loading: true 
      }));
    
      navigate(`/${category.name.toLowerCase().replace(/\s+/g, '-')}`);
    
      try {
        const response = await api.get('/api/items/', {
          params: {
            category: category.id,
            page: 1,
            page_size: 10
          }
        });
        
        setState(prev => ({
          ...prev,
          allItems: response.data.results || [],
          hasMore: response.data.next !== null,
          currentPage: 1,
          loading: false
        }));
      } catch (error) {
        setState(prev => ({
          ...prev,
          error: 'Failed to fetch category items',
          loading: false
        }));
      }
    },

    clearCategory: async () => {
      setState(prev => ({ 
        ...prev, 
        selectedCategory: null,
        allItems: [],
        selectedFilter: 'all',
        allItemsLoading: true
      }));
      
      navigate('/');
      
      try {
        const response = await apiService.fetchAllItems(1);
        setState(prev => ({
          ...prev,
          allItems: response.items,
          hasMore: response.hasMore,
          currentPage: 1,
          allItemsLoading: false
        }));
      } catch (error) {
        setState(prev => ({
          ...prev,
          error: 'Failed to fetch items',
          allItemsLoading: false
        }));
      }
    },

    selectSuggestion: (suggestion) => {
      if (suggestion) {
        setState(prev => ({ 
          ...prev, 
          searchTerm: typeof suggestion === 'string' ? suggestion : suggestion.name,
          suggestions: [] 
        }));
      }
    },

    selectItem: (item) => {
      setState(prev => ({ ...prev, selectedItem: item }));
    },

    goBack: () => {
      setState(prev => ({ ...prev, selectedItem: null }));
    },

    addToCart: (e, item) => {
      e.stopPropagation();
      addToCart(item);
    },

    buyNow: (e, item) => {
      e.stopPropagation();
      buyNow(item);
    }
  }), [state.loadingMore, state.currentPage, state.selectedFilter, navigate, addToCart, buyNow]);

  // Update the useEffect:
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const [categoriesData, featuredItemsData] = await Promise.all([
          apiService.fetchCategories(),
          apiService.fetchFeaturedItems()
        ]);

      // If no category is selected, then load all items
      const allItemsData = state.selectedFilter === 'all' ? 
        await apiService.fetchAllItems(1) : 
        { items: [], hasMore: false };

      setState(prev => ({
        ...prev,
        categories: categoriesData,
        featuredItems: featuredItemsData,
        allItems: allItemsData.items,
        hasMore: allItemsData.hasMore,
        loading: false
      }));

      if (categorySlug) {
        const category = categoriesData.find(
          cat => cat.name.toLowerCase().replace(/\s+/g, '-') === categorySlug
        );
        if (category) {
          handlers.selectCategory(category);
        }
      }
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: 'Failed to load initial data',
        loading: false
      }));
    }
  };

  loadInitialData();
}, [categorySlug, handlers, state.selectedFilter]); 

  // Featured Items Auto-scroll
  useEffect(() => {
    const intervalId = setInterval(() => {
      setState(prev => ({
        ...prev,
        currentPairIndex: (prev.currentPairIndex + 1) >= Math.ceil(prev.featuredItems.length / 2) 
          ? 0 
          : prev.currentPairIndex + 1
      }));
    }, 5000);

    return () => clearInterval(intervalId);
  }, [state.featuredItems.length]);

  // Render loading state
  if (state.loading) {
    return (
      <HomeContainer>
        <LoadingContainer>Loading...</LoadingContainer>
      </HomeContainer>
    );
  }

  // Render error state
  if (state.error) {
    return (
      <HomeContainer>
        <ErrorContainer>{state.error}</ErrorContainer>
      </HomeContainer>
    );
  }

  // Render item details
  if (state.selectedItem) {
    return (
      <HomeContainer>
        <ItemDetails
          item={state.selectedItem}
          onBackClick={handlers.goBack}
          onAddToCart={handlers.addToCart}
          onBuyNow={handlers.buyNow}
        />
      </HomeContainer>
    );
  }

  // Render category view
  if (state.selectedCategory) {
    return (
      <HomeContainer>
        <BackButton onClick={handlers.clearCategory}>
          ← Back to Categories
        </BackButton>
        <SectionTitle>{state.selectedCategory.name} Items</SectionTitle>
        {state.loading ? (
          <LoadingContainer>Loading items...</LoadingContainer>
        ) : (
          <>
            <ItemsGrid>
              {state.allItems.map(renderItemCard)}
            </ItemsGrid>
            
            {state.hasMore && (
              <LoadMoreButton 
                onClick={handlers.loadMore}
                disabled={state.loadingMore}
              >
                {state.loadingMore ? 'Loading...' : 'Load More'}
              </LoadMoreButton>
            )}
          </>
        )}
      </HomeContainer>
    );
  }

  // Render main content
  return (
    <HomeContainer>
      <SearchBar
        searchTerm={state.searchTerm}
        suggestions={state.suggestions}
        onSearch={handlers.search}
        onSuggestionClick={handlers.selectSuggestion}
        onClear={() => setState(prev => ({ ...prev, searchTerm: '', suggestions: [] }))}
      />

      <SectionTitle>Categories</SectionTitle>
      <CategoriesContainer>
        {state.categories.map((category) => (
          <CategoryCard 
            key={category.id} 
            onClick={() => handlers.selectCategory(category)}
          >
            <CategoryTitle>{category.name}</CategoryTitle>
          </CategoryCard>
        ))}
      </CategoriesContainer>

      <SectionTitle>Featured Items</SectionTitle>
      <FeaturedSlider
        items={state.featuredItems || []}
        onItemClick={handlers.selectItem}
        onAddToCart={handlers.addToCart}
        onBuyNow={handlers.buyNow}
      />

<AllItemsSection 
    categories={state.categories}
    onItemClick={handlers.selectItem}
    onAddToCart={handlers.addToCart}
    onBuyNow={handlers.buyNow}
    formatCurrency={formatCurrency}
  />
    </HomeContainer>
  );
};

export default HomePage;