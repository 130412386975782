// src/components/common/ActionButton.js
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }

  ${({ $variant }) => {
    switch ($variant) {
      case 'cart':
        return `
          background-color: #4CAF50;
          color: white;
          &:hover { background-color: #45a049; }
        `;
      case 'buy':
        return `
          background-color: #2196F3;
          color: white;
          &:hover { background-color: #1e88e5; }
        `;
      case 'back':
        return `
          background-color: #007bff;
          color: white;
          &:hover { background-color: #0056b3; }
        `;
      default:
        return '';
    }
  }}
`;

const ActionButton = ({ children, variant, ...props }) => {
  return (
    <StyledButton $variant={variant} {...props}>
      {children}
    </StyledButton>
  );
};

export default ActionButton;