import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.prizecho.com',
});

// Request interceptor with category handling
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Handle category filtering in GET requests
    if (config.method === 'get' && config.params?.category) {
      // Ensure category parameter is properly formatted
      config.params = {
        ...config.params,
        category: encodeURIComponent(config.params.category)
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

let refreshTokenFunction = null;

export const setRefreshTokenFunction = (func) => {
  refreshTokenFunction = func;
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        if (!refreshTokenFunction) {
          throw new Error('Refresh token function not set');
        }
        const newAccessToken = await refreshTokenFunction();
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// Helper function for category-related API calls
export const categoryAPI = {
  getItemsByCategory: async (categoryId, categoryName) => {
    try {
      const response = await api.get('/api/items/', {
        params: {
          category: categoryId,
          category_name: categoryName,
          limit: 100 // Adjust based on your needs
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching category items:', error);
      throw error;
    }
  }
};

export default api;