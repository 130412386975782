import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SearchContainer = styled.div`
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%) translateY(${props => props.$hide ? '-100%' : '0'});
  width: 100%;
  max-width: 600px;
  z-index: 999;
  transition: transform 0.3s ease;
  padding: 0 20px;
`;

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 45px;
  transition: border-color 0.2s ease;
  background-color: white;
  
  &:focus {
    outline: none;
    border-color: #2196f3;
  }

  &::placeholder {
    color: #999;
  }
`;

const SuggestionsList = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  list-style-type: none;
  padding: 6px;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const SuggestionItem = styled.li`
  padding: 10px 14px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const SearchBar = ({ searchTerm, suggestions, onSearch, onSuggestionClick }) => {
  const [hideSearch, setHideSearch] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        onSuggestionClick(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSuggestionClick]);

  useEffect(() => {
    const handleScroll = () => {
      // Hide search bar when scrolling down, show when scrolling up
      const currentScrollY = window.scrollY;
      setHideSearch(currentScrollY > 100);
    };
 
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (searchTerm?.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    // Navigate immediately when suggestion is clicked
    navigate(`/search?q=${encodeURIComponent(suggestion)}`);
    // Update the search term
    onSearch(suggestion);
    // Clear suggestions
    onSuggestionClick(null);
  };

  return (
    <SearchContainer $hide={hideSearch}>
      <form onSubmit={handleSearchSubmit}>
        <SearchWrapper>
          <SearchInput
            type="text"
            placeholder="Search for items..."
            value={searchTerm || ''}
            onChange={(e) => onSearch(e.target.value)}
            aria-label="Search for items"
            autoComplete="off"
          />
        </SearchWrapper>
      </form>

      {suggestions?.length > 0 && (
        <SuggestionsList>
          {suggestions.map((suggestion, index) => (
            <SuggestionItem
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
    </SearchContainer>
  );
};

export default SearchBar;