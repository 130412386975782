import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../AuthContext';
import { FaUser, FaCaretDown, FaWallet, FaBars, FaShoppingCart } from 'react-icons/fa'; // Remove FaBell
import NotificationMenu from './NotificationMenu';
import { useCart } from '../CartContext';

const HeaderContainer = styled.header`
    background-color: #000000;
    color: #EBE9E1;
    padding: 0.5rem 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    transition: transform 0.3s ease;
    transform: translateY(${props => props.$isHidden ? '-100%' : '0'});
  `;

const NavContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

const LogoLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  transform: scale(1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const Logo = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: 800;
  color: #EBE9E1;
  letter-spacing: 1px;
`;

const NavLinks = styled.nav`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
`;

const StyledLink = styled(Link)`
  color: #EBE9E1;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 0;
    background: #EBE9E1;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #EFB11D;
    &::after {
      width: 100%;
    }
  }
`;

const ProfileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: #EBE9E1;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    color: #EFB11D;
    transform: translateY(-2px);
  }
`;

const Badge = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  background: linear-gradient(135deg, #EFB11D, #FF8C00);
  color: #000;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
  min-width: 18px;
  text-align: center;
  font-weight: 600;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 1rem);
  right: 0;
  background: #000000;
  border: 1px solid #333;
  border-radius: 12px;
  padding: 0.5rem;
  min-width: 200px;
  opacity: ${props => (props.$isOpen ? '1' : '0')};
  visibility: ${props => (props.$isOpen ? 'visible' : 'hidden')};
  transform: ${props => (props.$isOpen ? 'translateY(0)' : 'translateY(-10px)')};
  transition: all 0.3s ease;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
`;

const DropdownItem = styled.div`
  color: #ffffff;
  padding: 0.75rem 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(239, 177, 29, 0.1);
    color: #EFB11D;
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const MobileMenuIcon = styled(FaBars)`
  display: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: 767px) {
    display: block;
  }

  &:hover {
    color: #EFB11D;
  }
`;

const MobileMenu = styled.div`
  display: ${props => props.$isOpen ? 'flex' : 'none'};
  flex-direction: column;
  background: #000000;
  padding: 1rem;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  gap: 1rem;
  z-index: 1000;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    display: none;
  }
`;

const ProfileIcon = styled(FaUser)`
  color: #EBE9E1;
  font-size: 1.2rem;
  margin-right: 0.5rem;
  transition: color 0.3s ease;
`;

const Header = () => {
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const lastScrollY = useRef(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, logout } = useAuth();
  const { getCartItemsCount } = useCart();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
    setDropdownOpen(false);
    setMobileMenuOpen(false);
  };

  const handleWalletClick = () => {
    navigate('/wallet');
    setMobileMenuOpen(false);
  };

  const handleCartClick = () => {
    navigate('/cart');
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsHeaderHidden(currentScrollY > lastScrollY.current && currentScrollY > 80);
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderContainer $isHidden={isHeaderHidden}>
      <NavContainer>
        <LogoLink to="/">
          <Logo>Prizecho</Logo>
        </LogoLink>

        <NavLinks>
          <StyledLink to="/">Home</StyledLink>
          <StyledLink to="/about">About</StyledLink>
          <StyledLink to="/contact">Contact</StyledLink>

          {user && (
            <ProfileContainer onClick={toggleDropdown} ref={dropdownRef}>
              <ProfileIcon />
              <span>{user.username}</span>
              <FaCaretDown color="#EBE9E1" />
              <DropdownMenu $isOpen={dropdownOpen}>
                <DropdownItem as={Link} to="/profile">Profile</DropdownItem>
                <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                </DropdownMenu>
            </ProfileContainer>
          )}
          {!user && <StyledLink to="/login">Login</StyledLink>}
        </NavLinks>

        <RightContainer>
          {user && (
            <>
              <NotificationMenu />
              <IconButton onClick={handleCartClick}>
                <FaShoppingCart />
                {getCartItemsCount() > 0 && (
                  <Badge>{getCartItemsCount()}</Badge>
                )}
              </IconButton>
              <IconButton onClick={handleWalletClick}>
                <FaWallet />
              </IconButton>
            </>
          )}
          <MobileMenuIcon onClick={toggleMobileMenu} />
        </RightContainer>
      </NavContainer>

      <MobileMenu $isOpen={mobileMenuOpen}>
  <StyledLink to="/" onClick={toggleMobileMenu}>Home</StyledLink>
  <StyledLink to="/about" onClick={toggleMobileMenu}>About</StyledLink>
  <StyledLink to="/contact" onClick={toggleMobileMenu}>Contact</StyledLink>
  
  {user ? (
    <>
      <StyledLink to="/profile" onClick={toggleMobileMenu}>Profile</StyledLink>
      <StyledLink as="div" onClick={handleLogout}>Logout</StyledLink>
    </>
  ) : (
    <StyledLink to="/login" onClick={toggleMobileMenu}>Login</StyledLink>
  )}
</MobileMenu>
    </HeaderContainer>
  );
};

export default Header;