// src/components/common/ItemCard.js
import React, { memo } from 'react';
import styled from 'styled-components';
import { FaUsers } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ActionButton from './ActionButton';

const CardBase = styled.div`
  width: 280px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #f5f5f5;
`;

const StyledImage = styled(LazyLoadImage)`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  transition: transform 0.3s ease;
  padding: 8px;

  ${CardBase}:hover & {
    transform: scale(1.05);
  }
`;

const ContentContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  font-size: 1em;
  margin: 0;
  color: #333;
  line-height: 1.4;
`;

const Price = styled.p`
  font-weight: bold;
  color: #e43d12;
  font-size: 1.1em;
  margin: 0;
`;

const ProgressContainer = styled.div`
  margin: 4px 0 12px;
`;

const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const ApplicationCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9em;
  color: #666;

  svg {
    color: #2196F3;
  }
`;

const ApplicationText = styled.span`
  color: #2196F3;
  font-weight: 500;
`;

const GoalAmount = styled.span`
  font-size: 0.9em;
  color: #666;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  width: ${props => props.$progress}%;
  height: 100%;
  background-color: #2196F3;
  border-radius: 2px;
  transition: width 0.3s ease;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 4px;
`;

const formatCurrency = (amount) => {
  if (amount === undefined || amount === null) return 'Rs. 0';
  
  try {
    return `Rs. ${Number(amount).toLocaleString()}`;
  } catch (error) {
    console.error('Error formatting currency:', error);
    return 'Rs. 0';
  }
};

const ItemCard = ({ item = {}, onClick, onAddToCart, onBuyNow }) => {
  console.log('Full Item Data:', JSON.stringify(item, null, 2));


  const {
    image,
    title = '',
    price = 0,
    target_amount = 0,     // Changed from targetAmount
    current_amount = 0,    // Changed from currentAmount
    applicants_count = 0   // Changed from applicantsCount
  } = item;

  console.log({
    target_amount,
    current_amount,
    applicants_count,
    price
  });

  // Update this to use the correct variable names
  const numericApplicants = Number(applicants_count) || 0;
  const progress = target_amount > 0 ? (current_amount / target_amount) * 100 : 0;

  const handleAddToCart = (e) => {
    e.stopPropagation();
    onAddToCart?.(e, item);
  };

  const handleBuyNow = (e) => {
    e.stopPropagation();
    onBuyNow?.(e, item);
  };

  return (
    <CardBase onClick={onClick}>
      <ImageContainer>
        <StyledImage
          src={image || '/placeholder-image.jpg'}
          alt={title}
          effect="blur"
          placeholder={
            <div style={{ width: '100%', aspectRatio: '1', backgroundColor: '#f0f0f0' }} />
          }
        />
      </ImageContainer>

      <ContentContainer>
        <Title>{title}</Title>
        <Price>{formatCurrency(price)}</Price>

        <ProgressContainer>
          <ProgressHeader>
            <ApplicationCount>
              <FaUsers size={14} />
              <ApplicationText>{numericApplicants} Applied</ApplicationText>
            </ApplicationCount>
            <GoalAmount>Goal: {formatCurrency(target_amount)}</GoalAmount>
          </ProgressHeader>
          <ProgressBarContainer>
            <ProgressBar $progress={progress} />
          </ProgressBarContainer>
        </ProgressContainer>

        <ButtonContainer>
          <ActionButton
            onClick={handleAddToCart}
            $variant="cart"
            style={{ width: '100%' }}
          >
            Cart
          </ActionButton>
          <ActionButton
            onClick={handleBuyNow}
            $variant="buy"
            style={{ width: '100%' }}
          >
            Apply Now
          </ActionButton>
        </ButtonContainer>
      </ContentContainer>
    </CardBase>
  );
};

export default memo(ItemCard);