import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ItemCard from '../common/ItemCard';

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
`;

const SliderTrack = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(-${props => props.$offset}%);
`;

const SlideWrapper = styled.div`
  flex: 0 0 25%;
  padding: 0 10px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    flex: 0 0 33.333%;
  }

  @media (max-width: 768px) {
    flex: 0 0 50%;
  }

  @media (max-width: 480px) {
    flex: 0 0 100%;
  }
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  ${props => props.$left ? 'left: 10px;' : 'right: 10px;'}

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`;

const FeaturedSlider = ({ items, onItemClick, onAddToCart, onBuyNow }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerView, setItemsPerView] = useState(4);

  // Update items per view based on screen size
  useEffect(() => {
    const updateItemsPerView = () => {
      if (window.innerWidth <= 480) {
        setItemsPerView(1);
      } else if (window.innerWidth <= 768) {
        setItemsPerView(2);
      } else if (window.innerWidth <= 1024) {
        setItemsPerView(3);
      } else {
        setItemsPerView(4);
      }
    };

    updateItemsPerView();
    window.addEventListener('resize', updateItemsPerView);
    return () => window.removeEventListener('resize', updateItemsPerView);
  }, []);

  const handleNext = useCallback(() => {
    setCurrentIndex(prev => {
      if (prev >= items.length - itemsPerView) {
        return 0;
      }
      return prev + 1;
    });
  }, [items.length, itemsPerView]);

  const handlePrev = useCallback(() => {
    setCurrentIndex(prev => {
      if (prev === 0) {
        return Math.max(items.length - itemsPerView, 0);
      }
      return Math.max(prev - 1, 0);
    });
  }, [items.length, itemsPerView]);

  // Auto-scroll effect
  useEffect(() => {
    if (items.length <= itemsPerView) return;

    const interval = setInterval(handleNext, 3000);
    return () => clearInterval(interval);
  }, [handleNext, items.length, itemsPerView]);

  // Create a duplicated array for infinite scroll effect
  const displayItems = [...items, ...items.slice(0, itemsPerView)];

  return (
    <SliderContainer>
      {items.length > itemsPerView && (
        <NavButton $left onClick={handlePrev}>
          <FaChevronLeft />
        </NavButton>
      )}

      <SliderTrack $offset={(currentIndex * (100 / itemsPerView))}>
        {displayItems.map((item, index) => (
          <SlideWrapper key={`${item.id}-${index}`}>
            <ItemCard
              item={item}
              onClick={() => onItemClick(item)}
              onAddToCart={(e) => onAddToCart(e, item)}
              onBuyNow={(e) => onBuyNow(e, item)}
              featured
            />
          </SlideWrapper>
        ))}
      </SliderTrack>

      {items.length > itemsPerView && (
        <NavButton onClick={handleNext}>
          <FaChevronRight />
        </NavButton>
      )}
    </SliderContainer>
  );
};

export default FeaturedSlider;