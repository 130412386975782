import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../utils/api';
import LoadingSpinner from '../LoadingSpinner';
import Custom404Page from '../Custom404Page';
import HomePage from '../HomePage/HomePage';
import styled from 'styled-components';

// Styled component for the loading container
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
`;

const ValidCategoryRoute = () => {
  const { categorySlug } = useParams();
  const [routeState, setRouteState] = useState({
    isLoading: true,
    isValid: false,
    category: null
  });
  
  useEffect(() => {
    const validateCategory = async () => {
      try {
        const response = await api.get('/api/categories/');
        const categories = Array.isArray(response.data) ? response.data : response.data.results || [];
        
        const category = categories.find(
          cat => cat.name.toLowerCase().replace(/\s+/g, '-') === categorySlug
        );
        
        setRouteState({
          isLoading: false,
          isValid: !!category,
          category: category || null
        });
      } catch (error) {
        console.error('Error validating category:', error);
        setRouteState({
          isLoading: false,
          isValid: false,
          category: null
        });
      }
    };

    if (categorySlug) {
      validateCategory();
    }
  }, [categorySlug]);

  if (routeState.isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (!routeState.isValid) {
    return <Custom404Page />;
  }

  // Pass the validated category to HomePage
  return <HomePage initialCategory={routeState.category} />;
};

export default ValidCategoryRoute;