
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaTwitter, FaFacebook, FaYoutube, FaInstagram, FaTiktok, FaWhatsapp, FaEnvelope, FaPhone } from 'react-icons/fa';


const FooterContainer = styled.footer`
  background-color: #000000;
  color: #EBE9E1;
  padding: 40px 20px;
  text-align: center;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SocialText = styled.p`
  margin-bottom: 20px;
`;

const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const SocialLink = styled.a`
  color: #EBE9E1;
  font-size: 24px;
  margin: 0 15px;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: #a0a0a0;
    transform: scale(1.1);
  }
`;

const ContactInfo = styled.div`
  margin-bottom: 20px;
`;

const ContactItem = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  svg {
    margin-right: 10px;
  }
`;

const Divider = styled.hr`
  width: 50%;
  border: none;
  border-top: 1px solid #333;
  margin: 20px 0;
`;

const CopyrightText = styled.p`
  font-size: 0.9em;
  opacity: 0.8;
`;

const PolicyLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PolicyLink = styled(Link)`
  color: #EBE9E1;
  text-decoration: none;
  margin: 0 10px;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    color: #a0a0a0;
  }
`;


const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <SocialText>Follow us on social media for the latest updates and contests!</SocialText>
        <SocialLinksContainer>
          <SocialLink href="https://twitter.com/prizecho" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </SocialLink>
          <SocialLink href="https://www.facebook.com/profile.php?id=61563809173445" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </SocialLink>
          <SocialLink href="https://youtube.com/@Prizecho" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </SocialLink>
          <SocialLink href="https://instagram.com/prizecho" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </SocialLink>
          <SocialLink href="https://tiktok.com/@prizecho" target="_blank" rel="noopener noreferrer">
            <FaTiktok />
          </SocialLink>
          <SocialLink href="https://wa.me/923209884002" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp />
          </SocialLink>
        </SocialLinksContainer>

        <ContactInfo>
          <ContactItem>
            <FaEnvelope /> support@prizecho.com
          </ContactItem>
          <ContactItem>
            <FaPhone /> +92 320 9884002
          </ContactItem>
        </ContactInfo>

        <PolicyLinksContainer>
          <PolicyLink to="/faq">FAQ</PolicyLink>
          <PolicyLink to="/guides">User Guides</PolicyLink>
          <PolicyLink to="/blog">Blog</PolicyLink>
          <PolicyLink to="/sitemap">Site Map</PolicyLink>
          <PolicyLink to="/privacy-policy">Privacy Policy</PolicyLink>
          <PolicyLink to="/terms-of-service">Terms of Service</PolicyLink>
          <PolicyLink to="/fbr-documents">FBR Documents</PolicyLink>
        </PolicyLinksContainer>

        <Divider />

        <CopyrightText>&copy; 2024 Prizecho. All rights reserved.</CopyrightText>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;