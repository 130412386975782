import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { FaShoppingCart, FaBolt, FaUsers } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styled from 'styled-components';

const SectionTitle = styled.h2`
  font-size: 1.5em;
  color: #333;
  margin: 30px 0 20px;
  text-align: center;
`;

const CategoryFilter = styled.div`
  display: flex;
  gap: 12px;
  overflow-x: auto;
  padding: 12px 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #eee;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  width: 100%;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FilterButton = styled.button`
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  font-size: 0.95em;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease;
  background-color: ${props => props.$active ? '#2196F3' : '#f8f9fa'};
  color: ${props => props.$active ? 'white' : '#666'};
  font-weight: ${props => props.$active ? '600' : '500'};
  box-shadow: ${props => props.$active ? '0 2px 8px rgba(33, 150, 243, 0.2)' : 'none'};

  &:hover {
    background-color: ${props => props.$active ? '#1976D2' : '#f0f0f0'};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2em;
  color: #666;
`;

const ItemsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
  margin: 20px 0;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const ItemCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  }
`;

const ItemImage = styled(LazyLoadImage)`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const ItemTitle = styled.h3`
  font-size: 1.1em;
  margin: 8px 0;
  color: #333;
`;

const ItemPrice = styled.p`
  font-weight: bold;
  color: #e43d12;
  font-size: 1.1em;
  margin: 8px 0;
`;

const ProgressContainer = styled.div`
  margin: 4px 0 12px;
`;

const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const ApplicationCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9em;
  color: #666;

  svg {
    color: #2196F3;
  }
`;

const ApplicationText = styled.span`
  color: #2196F3;
  font-weight: 500;
`;

const GoalAmount = styled.span`
  font-size: 0.9em;
  color: #666;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  width: ${props => props.$progress}%;
  height: 100%;
  background-color: #2196F3;
  border-radius: 2px;
  transition: width 0.3s ease;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: auto;
  padding-top: 12px;
`;

const ActionButton = styled.button`
  flex: 1;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

const AddToCartButton = styled(ActionButton)`
  background-color: #4CAF50;
  color: white;

  &:hover {
    background-color: #45a049;
  }
`;

const BuyButton = styled(ActionButton)`
  background-color: #2196F3;
  color: white;

  &:hover {
    background-color: #1e88e5;
  }
`;

const LoadMoreButton = styled.button`
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  margin: 32px auto;
  display: block;
  transition: all 0.3s ease;

  &:hover {
    background-color: #1976D2;
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const AllItemsContainer = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AllItemsSection = ({ 
  categories, 
  onItemClick, 
  onAddToCart, 
  onBuyNow,
  formatCurrency 
}) => {
  const [state, setState] = useState({
    allItems: [],
    allItemsLoading: true, // Changed to true initially
    selectedFilter: 'all',
    currentPage: 1,
    hasMore: true,
    loadingMore: false
  });

  // Add useEffect to fetch items on mount
  useEffect(() => {
    const loadInitialItems = async () => {
      try {
        const response = await fetchItems('all', 1);
        setState(prev => ({
          ...prev,
          allItems: response.items,
          hasMore: response.hasMore,
          allItemsLoading: false
        }));
      } catch (error) {
        console.error('Error loading initial items:', error);
        setState(prev => ({
          ...prev,
          allItemsLoading: false
        }));
      }
    };

    loadInitialItems();
  }, []); // Empty dependency array means this runs once on mount

  const fetchItems = async (categoryId, page = 1) => {
    try {
      const response = await api.get('/api/items/', {
        params: {
          ...(categoryId !== 'all' && { category: categoryId }),
          page,
          page_size: 10
        }
      });
      
      return {
        items: response.data.results || [],
        hasMore: response.data.next !== null
      };
    } catch (error) {
      console.error('Error fetching items:', error);
      return { items: [], hasMore: false };
    }
  };

  const handleFilterClick = async (categoryId) => {
    setState(prev => ({
      ...prev,
      selectedFilter: categoryId,
      allItemsLoading: true,
      allItems: [],
      currentPage: 1
    }));

    const response = await fetchItems(categoryId);
    
    setState(prev => ({
      ...prev,
      allItems: response.items,
      hasMore: response.hasMore,
      allItemsLoading: false
    }));
  };

  const handleLoadMore = async () => {
    if (state.loadingMore) return;

    setState(prev => ({ ...prev, loadingMore: true }));
    
    const response = await fetchItems(state.selectedFilter, state.currentPage + 1);
    
    setState(prev => ({
      ...prev,
      allItems: [...prev.allItems, ...response.items],
      currentPage: prev.currentPage + 1,
      hasMore: response.hasMore,
      loadingMore: false
    }));
  };

  return (
    <AllItemsContainer>
      <SectionTitle>All Items</SectionTitle>
      <CategoryFilter>
        <FilterButton 
          $active={state.selectedFilter === 'all'}
          onClick={() => handleFilterClick('all')}
        >
          All Items
        </FilterButton>
        {categories.map((category) => (
          <FilterButton
            key={category.id}
            $active={state.selectedFilter === category.id}
            onClick={() => handleFilterClick(category.id)}
          >
            {category.name}
          </FilterButton>
        ))}
      </CategoryFilter>

      {state.allItemsLoading ? (
        <LoadingContainer>Loading items...</LoadingContainer>
      ) : (
        <>
          <ItemsGrid>

{state.allItems.map((item) => (
  <ItemCard 
    key={item.id}
    onClick={() => onItemClick(item)}
  >
    <ItemImage
      src={item.image || '/placeholder-image.jpg'}
      alt={item.title}
      effect="blur"
    />
    <ItemTitle>{item.title}</ItemTitle>
    <ItemPrice>{formatCurrency(item.price)}</ItemPrice>
    
    <ProgressContainer>
      <ProgressHeader>
        <ApplicationCount>
          <FaUsers size={14} />
          <ApplicationText>
            {Number(item.applicants_count) || 0} Applied
          </ApplicationText>
        </ApplicationCount>
        <GoalAmount>
          Goal: {formatCurrency(item.target_amount)}
        </GoalAmount>
      </ProgressHeader>
      <ProgressBarContainer>
        <ProgressBar 
          $progress={
            item.target_amount > 0 
              ? (item.current_amount / item.target_amount) * 100 
              : 0
          } 
        />
      </ProgressBarContainer>
    </ProgressContainer>

    <ButtonContainer>
      <AddToCartButton 
        onClick={(e) => {
          e.stopPropagation();
          onAddToCart(e, item); // Changed this line
        }}
      >
        <FaShoppingCart /> Add
      </AddToCartButton>
      <BuyButton 
        onClick={(e) => {
          e.stopPropagation();
          onBuyNow(e, item); // Changed this line
        }}
      >
        <FaBolt /> Apply
      </BuyButton>
    </ButtonContainer>
  </ItemCard>
))}
          </ItemsGrid>

          {state.hasMore && (
            <LoadMoreButton 
              onClick={handleLoadMore}
              disabled={state.loadingMore}
            >
              {state.loadingMore ? 'Loading...' : 'Load More'}
            </LoadMoreButton>
          )}
        </>
      )}
    </AllItemsContainer>
  );
};

export default AllItemsSection;